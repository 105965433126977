import React from "react";
import {InputField} from "../input-field";
import SpellCheck from "../spellCheck";
import {Channel, Substitutions} from "../../types";
import {FieldError} from "react-hook-form";
import {useTranslation} from "../../hooks/use-translation";

type EmailInformationPropsType = {
    isGrammarCorrected: Array<string>;
    inputValue: Substitutions;
    setInputValue?:(value: (((prevState: Substitutions) => Substitutions) | Substitutions)) => void;
    register: (ref: Element | null) => void;
    errors?: { [key: string]: FieldError | undefined };
    setDmaName?: (name: string) => void;
    setIsGrammarCorrected? :  (value: (((prevState: Array<string>) => Array<string>) | Array<string>)) => void;
    isOpenAiActivated?: boolean;
    channel: Channel;
    handleOpenAiButtonClick:  (fieldName: any) => void;
    isContactingOpenAi: Array<string>;
    getValueByKey: (keyToFind: string) => string | undefined;
    contactOpenAi: (fieldName: string) => void;
    handleDisclaimer:(fieldName:string)=>void;
    hasAcceptedPolicy? :boolean;
    setHasAcceptedPolicy? :(v:boolean )=> void;
    disclaimer?: boolean;
    setDisclaimer?: (v:boolean)=>void;
}

export const EmailInformation: React.FC<EmailInformationPropsType> = ({
                                                                          isGrammarCorrected,
                                                                          inputValue,
                                                                          setInputValue,
                                                                          register,
                                                                          errors,
                                                                          setDmaName,
                                                                          setIsGrammarCorrected,
                                                                          isOpenAiActivated,
                                                                          channel,
                                                                          handleOpenAiButtonClick,
                                                                          isContactingOpenAi,
                                                                          getValueByKey,
                                                                          contactOpenAi,
                                                                          handleDisclaimer,
                                                                          hasAcceptedPolicy,
                                                                          setHasAcceptedPolicy,
                                                                          disclaimer,
                                                                          setDisclaimer
                                                                      }) => {
    const { translate } = useTranslation();
    return (
        <div
            key={"Email Information"}
            className="box is-shadowless is-paddingless">
            <h2 className="title is-5">{"Email Information"}</h2>
            <React.Fragment key={"Email Information_subject"}>
                <div className={"field is-horizontal"}>
                    <div className="field-body">
                        <InputField
                            name={"subject"}
                            customType={"shortText"}
                            isCorrected={isGrammarCorrected.includes("subject")}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            label={translate("preview.subject")}
                            placeholder={"ENTER EMAIL SUBJECT HERE"}
                            register={register}
                            errors={errors}
                            {...{
                                setDmaName,
                                setIsGrammarCorrected,
                            }}
                        />
                        {isOpenAiActivated && channel.includes("EMAIL") && (
                            <SpellCheck
                                handleClick={() => handleOpenAiButtonClick("subject")}
                                anyFieldIsCorrecting={!!isContactingOpenAi.length}
                                isCorrected={isGrammarCorrected.includes("subject")}
                                isLoading={isContactingOpenAi.includes("subject")}
                                isEmptyValue={getValueByKey("subject")?.trim() === ''}
                                contactOpenAi={contactOpenAi}
                                handleDisclaimer={handleDisclaimer}
                                fieldName={"subject"}
                                {...{
                                    hasAcceptedPolicy,
                                    setHasAcceptedPolicy,
                                    disclaimer,
                                    setDisclaimer,
                                }}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
            <React.Fragment key={"Email Information_replyTo"}>
                <div className={"field is-horizontal"}>
                    <div className="field-body">
                        <InputField
                            name={"replyTo"}
                            customType={"shortText"}
                            isCorrected={isGrammarCorrected.includes("replyTo")}
                            inputValue={inputValue}
                            setInputValue={setInputValue}
                            label={translate("preview.replyTo")}
                            placeholder={"ENTER EMAIL REPLY TO HERE"}
                            register={register}
                            errors={errors}
                            {...{
                                setDmaName,
                                setIsGrammarCorrected,
                            }}
                        />
                        {isOpenAiActivated && channel.includes("EMAIL") && (
                            <SpellCheck
                                handleClick={() => handleOpenAiButtonClick("replyTo")}
                                anyFieldIsCorrecting={!!isContactingOpenAi.length}
                                isCorrected={isGrammarCorrected.includes("replyTo")}
                                isLoading={isContactingOpenAi.includes("replyTo")}
                                isEmptyValue={getValueByKey("replyTo")?.trim() === ''}
                                contactOpenAi={contactOpenAi}
                                handleDisclaimer={handleDisclaimer}
                                fieldName={"replyTo"}
                                {...{
                                    hasAcceptedPolicy,
                                    setHasAcceptedPolicy,
                                    disclaimer,
                                    setDisclaimer,
                                }}
                            />
                        )}
                    </div>
                </div>
            </React.Fragment>
        </div>
    )
}
