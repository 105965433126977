import {useMutation, useQuery, useQueryCache} from "react-query";
import {apiDelete, apiGet, apiPatch, apiPost} from "../api/api-calls";
import {Channel} from "../types";
import {Campaign, ReportCampaign} from "../types/campaigns";
import {useNotification} from "./use-notification";
import {NewEvent} from "./use-event";
import {useTranslation} from "./use-translation";

const fetchCampaign = (_key: "campaigns", campaignId: string, channel: Channel) => {
    return apiGet<Campaign>(`/api/campaigns/${campaignId}?channel=${channel}`);
};

export function useCampaign(campaignId?: string, channel?: Channel) {
  const { data } = useQuery(["campaigns", campaignId, channel], fetchCampaign, {
    enabled: campaignId !== undefined,
    refetchOnWindowFocus: false,
  });
  const { name: fetchedCampaignName, status ,subscription} = data || { name: "" };
  return { data, fetchedCampaignName, status ,subscription};
}


export function useCampaignMutators() {
  const queryCache = useQueryCache();
  const {addNotification} = useNotification();
  const {translate} = useTranslation();

  type SendParams = { user: string; campaignId: string; comment?: string; approver?: string; sendingDate?: string };

  async function sendCampaignCall({user, campaignId, comment = "", sendingDate}: SendParams) {
    const eventRegister = {
      SCHEDULED: {
        start: "Starting scheduling",
        done: "Campaign scheduled",
        eventError: "Error: Campaign not scheduled",
        systemError: "Couldn't schedule campaign",
      },
      SENT: {
        start: "Starting sending",
        done: "Campaign sent",
        eventError: "Error: Campaign not send",
        systemError: "Couldn't send campaign",
      },
    };

    const notificationRegister = {
      SCHEDULED: {
        success: "notification.scheduled.success",
        error: "notification.scheduled.error",
      },
      SENT: {
        success: "notification.send.success",
        error: "notification.send.error",
      },
    };

    const sendingType = sendingDate ? "SCHEDULED" : "SENT";
    const apiUrl =
        sendingType === "SCHEDULED"
            ? `/api/campaigns/${campaignId}/send/${sendingDate}`
            : `/api/campaigns/${campaignId}/send`;

    const createEvent = (comment: string) => {
      return {campaignId, user, action: sendingType, comment};
    };

    await postEvent(createEvent(eventRegister[sendingType].start));
    try {
      await apiPost(apiUrl);
      addNotification({message: translate(notificationRegister[sendingType].success), props: {status: "success"}});
      await postEvent(createEvent(eventRegister[sendingType].done));
    } catch (e) {
      const data = await e.response.json();
      if(data && data.error && data.error && data.error === "email-campaign-group-not-exists-in-actito") {
        await postEvent(createEvent(eventRegister[sendingType].eventError));
        addNotification({message: translate("notification.send.error.campaignGroup"), props: {status: "error"}});
        throw new Error(eventRegister[sendingType].systemError);
      } else {
        await postEvent(createEvent(eventRegister[sendingType].eventError));
        addNotification({message: translate(notificationRegister[sendingType].error), props: {status: "error"}});
        throw new Error(eventRegister[sendingType].systemError);
      }
    }
  }

  async function deleteCampaignCall({campaignId}: { campaignId: string }) {
    try {
      console.log(campaignId)
      await apiDelete(`/api/campaigns`, {campaignIds: [campaignId]});
      addNotification({message: translate("notification.delete.success"), props: {status: "success"}});
    } catch (e) {
      addNotification({message: translate("notification.delete.error"), props: {status: "error"}});
      throw new Error("Couldn't delete campaign");
    }
  }

  type CopyParams = { campaignId: string; user: string; newName: string, scope: string, channel: string };

  async function copyCampaignCall({campaignId, user, newName, scope, channel}: CopyParams) {
    try {
      await apiPost(`/api/campaigns/${campaignId}/copy`, {
        newName: newName,
        newCreator: user,
        scope: scope,
        channel: channel,
      });
      addNotification({message: translate("notification.copy.success"), props: {status: "success"}});
    } catch (e) {
      addNotification({message: translate("notification.copy.error"), props: {status: "error"}});
      throw new Error("Couldn't copy campaign");
    }
  }

  type showReportingParams = { campaignId?: string; };

  async function campaignReportCall({campaignId}: showReportingParams) {
    try {
      const reportingCampaign = await apiGet<ReportCampaign>(`/api/campaigns/${campaignId}/reporting`)
      addNotification({message: translate("notification.reporting.success"), props: {status: "success"}});
      return reportingCampaign;
    } catch (e) {
      addNotification({message: translate("notification.reporting.error"), props: {status: "error"}});
      throw new Error("Couldn't show reporting")
    }
  }

    type TestParams = { user: string; campaignId: string; recipientList: string[]; comment?: string; channel: Channel };

    async function testCampaignCall({user, campaignId, recipientList, comment = "", channel}: TestParams) {
      const testApiUrl = `/api/campaigns/${campaignId}/test`;
      try {
        await apiPost(testApiUrl, {recipientList});
        addNotification({message: translate("notification.test.success")});
        const event = {campaignId, user, action: "TESTED", comment: "Test sent"};
        await postEvent(event);
      } catch (e) {
        addNotification({message: translate("notification.test.error"), props: {status: "error"}});
        const event = {campaignId, user, action: "TESTED", comment: "Error: Test not sent"};
        await postEvent(event);
        throw new Error("Couldn't send Test");
      }
    }

    type CancelParams = { user: string; campaignId: string; comment?: string; approver?: string };

    async function cancelCampaignCall({user, campaignId, comment = ""}: CancelParams) {
      const event = {campaignId, user, action: "CANCELLED", comment: "User initialized campaign cancel"};

      await postEvent(event);
      try {
        await apiPost(`/api/campaigns/${campaignId}/cancel`);
        addNotification({message: translate("notification.cancel.success"), props: {status: "success"}});
        const event = {campaignId, user, action: "CANCELLED", comment: "Campaign successfully cancelled"};
        await postEvent(event);
      } catch (e) {
        addNotification({message: translate("notification.cancel.error"), props: {status: "error"}});
        const event = {campaignId, user, action: "CANCELLED", comment: "Error: Couldn't cancel campaign"};
        await postEvent(event);
        throw new Error("Couldn't cancel campaign");
      }
    }

    const [test, {isError: isErrorOnTest}] = useMutation(testCampaignCall, {onSuccess, onError});
    const [send, {isError: isErrorOnSend}] = useMutation(sendCampaignCall, {onSuccess, onError});
    const [deleteCampaign] = useMutation(deleteCampaignCall, {onSuccess, onError});
    const [copyCampaign] = useMutation(copyCampaignCall, {onSuccess, onError});
    const [reportingCampaign] = useMutation(campaignReportCall, {onSuccess, onError});
    const [cancel] = useMutation(cancelCampaignCall, {onSuccess, onError});
    const [updateStatus] = useMutation(updateCampaignStatus, {onSuccess, onError});

    return {
      test,
      send,
      cancel,
      deleteCampaign,
      copyCampaign,
      reportingCampaign,
      updateStatus,
      isErrorOnTest,
      isErrorOnSend
    };

    function onSuccess(_data: unknown, {campaignId}: { campaignId: string }) {
      queryCache.invalidateQueries(["campaigns"]);
      queryCache.invalidateQueries(["events", campaignId]);
    }

    function onError(_data: unknown, {campaignId}: { campaignId: string }) {
      queryCache.invalidateQueries(["campaigns"]);
      queryCache.invalidateQueries(["events", campaignId]);
    }
  }

  async function postEvent(event: NewEvent) {
    await apiPost(`/api/campaigns/${event.campaignId}/events`, event);
  }

  type StatusParams = {
    user: string;
    campaignId: string;
    status: Campaign["status"];
    comment?: string;
    approver?: string;
    shouldPostEvent?: boolean;
    enableWithActito?: boolean;
  };

  async function updateCampaignStatus({
                                        user,
                                        campaignId,
                                        status,
                                        shouldPostEvent = true,
                                        comment = "",
                                        approver = "",
                                        enableWithActito
                                      }: StatusParams) {
    // do something to not update the campaign if enableWithActito
    await apiPatch(`/api/campaigns/${campaignId}`, {status, approver,enableWithActito});
    if (shouldPostEvent) {
      const event = {campaignId, user, action: status, comment};
      await postEvent(event);
    }

}
